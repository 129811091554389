import {Box, Flex, Heading, Img, Stack, Text} from '@chakra-ui/react'
// @ts-ignore
import React from "react";

export const ImageCta = ({
                             heading,
                             subheading,
                             image,
                             bg
                         }: { heading: string, subheading: string, image?: string, bg: string }) => {
    return (
        <Box bg="gray.800" as="section" minH="140px" position="relative">
            <Box py={{base: 16, lg: 40}} pb={{base: 8, lg: 40}} position="relative" zIndex={1}>
                <Box maxW={{base: 'xl', md: 'full'}} mx="auto" px={{base: '6', md: '8'}} color="white"
                     textAlign={'center'} alignItems={'center'} display={'flex'} flexDirection={'column'}
                     justifyContent={'center'}>
                    {/*<Logo zIndex={'10000'} pos={'absolute'} top={'10px'} left={'10px'} maxW={'300px'}/>*/}
                    <Heading as="h2" size="3xl" maxW={'3xl'} fontWeight="extrabold">
                        {heading}
                    </Heading>
                    <Text fontSize={{base: '1.5rem', md: '2rem'}} mt="4" maxW={'3xl'} fontWeight={'100'
                    }>
                        {subheading}
                    </Text>
                    <Stack direction={{base: 'column', md: 'row'}} mt="10" spacing="4" justifyContent={'center'}>
                        {/*<Button*/}
                        {/*    bg="blue.600"*/}
                        {/*    color={'white'}*/}
                        {/*    px="8"*/}
                        {/*    rounded="full"*/}
                        {/*    size="lg"*/}
                        {/*    fontSize="md"*/}
                        {/*    fontWeight="bold"*/}
                        {/*    _hover={{*/}
                        {/*        bg: 'blue.800',*/}
                        {/*        cursor: 'pointer'*/}
                        {/*    }}*/}
                        {/*    onClick={handleBuyNowClick}*/}
                        {/*>*/}
                        {/*    Buy Now*/}
                        {/*</Button>*/}
                        {/*<HStack*/}
                        {/*    transition="background 0.2s"*/}
                        {/*    justify={{base: 'center', md: 'flex-start'}}*/}
                        {/*    color="white"*/}
                        {/*    rounded="full"*/}
                        {/*    fontWeight="bold"*/}
                        {/*    px="6"*/}
                        {/*    py="3"*/}
                        {/*    _hover={{bg: 'whiteAlpha.300'}}*/}
                        {/*    onClick={handleJoinListClick}*/}
                        {/*>*/}
                        {/*    <span>Join the list</span>*/}
                        {/*    <HiChevronRight/>*/}
                        {/*</HStack>*/}
                    </Stack>
                </Box>
            </Box>
            <Flex
                id="image-wrapper"
                position="absolute"
                insetX="0"
                insetY="0"
                w="full"
                h="full"
                overflow="hidden"
                align="center"
            >
                <Box position="relative" w="full" h="full">
                    <Img
                        src={image}
                        alt={heading}
                        w="full"
                        h="full"
                        objectFit="cover"
                        objectPosition="top bottom"
                        position="absolute"
                    />
                    <Box position="absolute" w="full" h="full" bg={bg}/>
                    <Box position="absolute" w="full" h="full" bg="blackAlpha.700"/>
                </Box>
            </Flex>
        </Box>
    )
}
