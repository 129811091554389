import React, {useState} from 'react';
import './App.css';
import SEO from "./components/SEO/Seo";
import {WithImageBackground} from "./components/WithImageBackground/WithImageBackground";
import {CareersWithAccordion} from "./components/CareersWithAccordion/CareersWithAccordion";
import {FooterWithSocialIcons} from "./components/FooterWithSocialIcons/FooterWithSocialIcons";
import {PopupWithDiscount} from "./components/PopupWithDiscount/PopupWithDiscount";
import {Box, Heading, List, ListItem, Spacer, Text, useDisclosure} from "@chakra-ui/react";
import {ImageCta} from "./components/ImageCta/ImageCta";
import image from './assets/home2.webp'
import image2 from './assets/home4.webp'
import {ImageText} from "./components/ImageText/ImageText";
import LogRocket from 'logrocket';
import {NavbarWithPopoverOnAccent} from "./components/NavbarWithPopoverOnAccent/NavbarWithPopoverOnAccent";
import {TestimonialsWithRating} from "./components/TestimonialWithRatingTwoColumns/App";

LogRocket.init('4zf1vv/bed-divider');

function App() {
    const {isOpen, onClose} = useDisclosure()
    const [color] = useState('')
    const [size] = useState('')
    return (
        <div>
            <SEO title={'Premiere Luxury Network'}/>
            <NavbarWithPopoverOnAccent/>
            <WithImageBackground useH1={true}/>
            <Box textAlign={'center'} maxW={'7xl'} mx={'auto'} id={"about"}>
                <Text py={24} px={5} textAlign={'center'} fontSize={{base: '1.5rem', md: '2.5rem'}} fontWeight={'100'}>Welcome
                    to Premiere Luxury
                    Network, a premier
                    property management company dedicated to providing unparalleled service to property owners. Our
                    mission is to bridge the
                    gap between property owners and reliable, high-quality tenants. With a focus on corporate and
                    professional clientele, we specialize in managing properties for short to medium-term leases,
                    ensuring a steady income and impeccable maintenance. Our team of experienced professionals is
                    committed to upholding the highest
                    standards in property management, ensuring peace of mind for all of our partners.</Text>
            </Box>
            <ImageCta heading={'Client-Centric Approach'}
                      subheading={' At Premiere Luxury Network, we offer a comprehensive suite of property\n' +
                          '                                   management services designed to maximize your investment and minimize\n' +
                          '                                   your hassles.'} image={image2}
                      bg={'blackAlpha.600'}/>
            <ImageText
                orderBase={'column'}
                orderMd={'row'}
                subheading={
                    <Box fontSize={{base: '1.5rem', md: '2rem'}} px={{base: 4, md: 0}} fontWeight={'100'}
                         id={"services"}>
                        <Heading as="h2" size="3xl" maxW={'full'} fontWeight="extrabold" mb={2}>
                            Tailored Solutions to Meet Your Unique Property Needs
                        </Heading>
                        <Spacer h={10}/>
                        <List spacing={3} pt={5}>
                            <ListItem>
                                <hr/>
                                <Box py={4}>
                                    <Text as={'span'} fontWeight={'normal'}>Tenant Screening and
                                        Placement</Text>
                                </Box>
                                <hr/>
                                <Spacer mb={5}/>
                                Rigorous background checks to ensure
                                responsible and reliable tenants.</ListItem>
                            <ListItem>
                                <hr/>
                                <Box py={4}>
                                    <Text as={'span'} fontWeight={'normal'}>Property Maintenance</Text>
                                </Box>
                                <hr/>
                                <Spacer mb={5}/>
                                Regular upkeep and prompt repairs, maintaining
                                your property at its best.</ListItem>
                            <ListItem>
                                <hr/>
                                <Box py={4}>
                                    <Text as={'span'} fontWeight={'normal'}>Lease Management</Text>
                                </Box>
                                <hr/>
                                <Spacer mb={5}/>
                                Handling all aspects of lease administration for
                                efficiency and compliance.</ListItem>
                            <ListItem>
                                <hr/>
                                <Box py={4}>
                                    <Text as={'span'} fontWeight={'normal'}>Financial
                                        Management</Text>
                                </Box>
                                <hr/>
                                <Spacer mb={5}/>
                                Ensuring timely rent collection and financial
                                reporting for transparency and ease.</ListItem>
                            <ListItem>
                                <hr/>
                                <Box py={4}>
                                    <Text as={'span'} fontWeight={'normal'}>24/7 Support</Text>
                                </Box>
                                <hr/>
                                <Spacer mb={5}/>
                                Our dedicated team is always available to address any
                                concerns or emergencies.</ListItem>
                        </List>
                    </Box>
                }
            />
            <CareersWithAccordion/>
            <ImageCta heading={'Our Commitment to Quality'}
                      subheading={'Delivering Exceptional Service and Value to Property Owners'} image={image}
                      bg={'blackAlpha.600'}/>

            <TestimonialsWithRating/>
            <FooterWithSocialIcons/>
            <PopupWithDiscount isOpen={isOpen} onClose={onClose} color={color} size={size}/>
        </div>
    );
}


export default App;
