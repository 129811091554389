import {Accordion, Box, Container, Heading, SimpleGrid, Spacer, Stack, Text} from '@chakra-ui/react'
import {JobPosting} from './JobPosting'
import {jobListings} from "./data";
import React from "react";

export const CareersWithAccordion = () => (
    <Container py={{base: '16', md: '24'}} minW={'full'} id={"faq"}>
        <Box maxW={{base: 'xl', md: '7xl'}} mx={'auto'}>
            <SimpleGrid columns={{base: 1, md: 1}} gap={12}>
                <Accordion defaultIndex={0}>
                    <Heading as="h2" size="3xl" maxW={'3xl'} fontWeight="extrabold" mb={2}>
                        FAQ
                    </Heading>
                    <Spacer h={10}/>
                    {/*<Image src={image} w={'full'}/>*/}
                    {/*<Heading as="h4" fontSize="26px" mb={6} mt={10}>FAQ</Heading>*/}
                    {jobListings.map((listing, id) => (
                        <JobPosting key={id} {...listing} />
                    ))}
                </Accordion>
                <Stack spacing={2} pt={24} id={"compliance"}>
                    <Heading as="h2" size="3xl" maxW={'3xl'} fontWeight="extrabold" mb={2}>
                        Compliance
                    </Heading>
                    <Text pt={4} fontSize={{base: '1.5rem', md: '2rem'}} fontWeight={'100'}>
                        At Premiere Luxury Network, we are deeply committed to adhering to all legal and regulatory
                        standards in property management. We understand the importance of compliance in our industry
                        and ensure that every aspect of our operation aligns with the latest laws and best
                        practices. Our team regularly undergoes training and stays updated on all relevant real
                        estate, zoning, and tenancy laws to provide you with a service that is not only efficient
                        but also fully compliant and ethical. Trust, transparency, and legal integrity are the
                        cornerstones of our business philosophy.
                    </Text>
                </Stack>
            </SimpleGrid>
        </Box>
    </Container>
)
