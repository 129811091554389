import * as React from "react";
import {Helmet} from "react-helmet";
// @ts-ignore
import metaImage from '../../assets/home2.webp';

// Define the type for the props that you expect
interface SEOProps {
    title?: string;
}

const SEO: React.FC<SEOProps> = ({title}) => {
    let url = '';

    // TypeScript-safe check for window object
    if (typeof window !== 'undefined') {
        url = window.location.href;
    }

    // Updated to a TypeScript arrow function with explicit return type
    const stripTrailingSlash = (str: string): string => {
        return str.replace(/\/$/, ''); // Replace trailing slash with an empty string
    };

    return (
        <Helmet htmlAttributes={{lang: "en"}} title={`${title ? title : 'Premiere Luxury Network'}`}>
            <meta name="description"
                  content={'Discover top-tier property management services with Premiere Luxury Network. Specializing in high-quality residential and commercial properties, ensuring maximum value and exceptional care.'}/>
            <meta name="image" content={metaImage}/>

            {/* Facebook Card */}
            <meta property="og:url" content={'www.premiereluxurynetwork.com'}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={`${title} | Premiere Luxury Network`}/>
            <meta property="og:description"
                  content={'Discover top-tier property management services with Premiere Luxury Network. Specializing in high-quality residential and commercial properties, ensuring maximum value and exceptional care.'}/>
            <meta property="og:image" content={metaImage}/>
            <meta property="og:image:width" content="400"/>
            <meta property="og:image:height" content="300"/>

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image"/>
            {/* Uncomment and fill in the values if you have them */}
            {/*<meta name="twitter:creator" content={twitterUsername}/>*/}
            {/*<meta name="twitter:title" content={siteTitle}/>*/}
            {/*<meta name="twitter:description" content={siteDesc}/>*/}
            <meta name="twitter:image" content={`${stripTrailingSlash(url)}${metaImage}`}/>
        </Helmet>
    );
}

export default SEO;
