import {Box, Container, Heading, Image, Stack,} from '@chakra-ui/react'
import React from "react";

export const ImageText = ({
                              img,
                              heading,
                              subheading,
                              orderBase,
                              orderMd
                          }: { img?: any, heading?: string, subheading: any, orderBase: "row" | "column" | "row-reverse" | "column-reverse" | undefined, orderMd: "row" | "column" | "row-reverse" | "column-reverse" | undefined }) => {

    return (
        <Box bg="bg.surface" minW={'full'}>
            <Container py={{base: '16', md: '24'}} maxW={{base: 'xl', md: '7xl'}} mx={'auto'} px={0}>
                <Stack
                    spacing="16"
                    direction={{base: orderBase, md: orderMd}}
                    align={{base: 'start', md: 'center'}}
                >
                    <Stack spacing={{base: '8', md: '10'}} width="full">
                        <Stack spacing={{base: '4', md: '6'}}>
                            {heading &&
                                <Heading size={{base: 'lg', md: 'lg'}} as={'h2'}>{heading}</Heading>
                            }
                            {subheading}
                        </Stack>
                        {/*<Button*/}
                        {/*    bg="blue.600"*/}
                        {/*    color={'white'}*/}
                        {/*    px="8"*/}
                        {/*    rounded="full"*/}
                        {/*    size="lg"*/}
                        {/*    fontSize="md"*/}
                        {/*    fontWeight="bold"*/}
                        {/*    _hover={{*/}
                        {/*        bg: 'blue.800',*/}
                        {/*        cursor: 'pointer'*/}
                        {/*    }}*/}
                        {/*    maxW={'300px'}*/}
                        {/*    onClick={handleBuyNowClick}*/}
                        {/*>*/}
                        {/*    Join the list*/}
                        {/*</Button>*/}
                        {/*<Stack*/}
                        {/*    direction={{ base: 'column', sm: 'row' }}*/}
                        {/*    width="full"*/}
                        {/*    maxW={{ md: 'lg' }}*/}
                        {/*    spacing="4"*/}
                        {/*>*/}
                        {/*    <FormControl flex="1">*/}
                        {/*        <Input type="email" size="xl" placeholder="Enter your email" />*/}
                        {/*        <FormHelperText color="fg.subtle">Read abotu your privacy policy</FormHelperText>*/}
                        {/*    </FormControl>*/}
                        {/*    <Button size="xl">Subscribe</Button>*/}
                        {/*</Stack>*/}
                    </Stack>
                    {img &&
                        <Box width="full">
                            <Image
                                boxSize="full"
                                alt={heading}
                                src={img}
                                objectFit="cover"
                            />
                        </Box>
                    }
                </Stack>
            </Container>
        </Box>
    )
}
