// eslint-disable-next-line no-unused-vars
import React, {createContext, ReactNode, useContext, useState} from 'react';

interface FormDataContextProps {
    formData: { [key: string]: any };
    updateFormData: (name: string, value: any) => void;
}

const FormDataContext = createContext<FormDataContextProps | undefined>(undefined);

export const useFormData = () => {
    const context = useContext(FormDataContext);
    if (!context) {
        throw new Error('useFormData must be used within a FormDataProvider');
    }
    return context;
};

interface FormDataProviderProps {
    children: ReactNode;
}

export const FormDataProvider: React.FC<FormDataProviderProps> = ({children}) => {
    const [formData, setFormData] = useState<{ [key: string]: any }>({});

    const updateFormData = (name: string, value: any) => {
        setFormData((prev) => ({...prev, [name]: value}));
    };

    return (
        <FormDataContext.Provider value={{formData, updateFormData}}>
            {children}
        </FormDataContext.Provider>
    );
};
