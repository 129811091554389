export const jobListings = [
    {
        "title": "What kind of properties do you manage?",
        "description": "We specialize in managing a variety of properties, including single-family homes, apartments, and condos. Our focus is on properties that meet our high standards for quality and location, catering to the needs of our corporate and professional clientele."
    },
    {
        "title": "How do you screen potential tenants?",
        "description": "Our tenant screening process is thorough and includes background checks, credit checks, employment verification, and previous rental history. This ensures that we find responsible and reliable tenants for your property."
    },
    {
        "title": "What is your approach to property maintenance?",
        "description": "We believe in proactive maintenance to keep properties in top condition. This includes regular inspections, timely repairs, and maintenance work, all handled by our team of experienced professionals."
    },
    {
        "title": "How are rent payments handled?",
        "description": "Rent is collected monthly through secure, convenient online payment systems. We ensure timely collection and provide you with regular financial statements for complete transparency."
    },
    {
        "title": "What happens if a tenant damages my property?",
        "description": "In the rare event of property damage, we handle all aspects of the repair process. We assess the damage, coordinate repairs, and manage any insurance claims. The tenant's security deposit can also be used to cover costs when applicable."
    },
    {
        "title": "Can I choose to approve tenants?",
        "description": "Yes, we work closely with property owners throughout the tenant selection process. While we handle the screening, you have the final say in tenant approval."
    }
]


export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
        infer ElementType
    >
    ? ElementType
    : never

export type JobListing = ElementType<typeof jobListings>
