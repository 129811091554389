import {Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text, useColorModeValue,} from '@chakra-ui/react'
import {SubscribeForm} from './SubscribeForm'
import {FormDataProvider} from "../../context/formDataContext";

export const PopupWithDiscount = ({isOpen, onClose, color, size}: { isOpen: boolean, onClose: any, color: string, size: string }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="2xl"
            // `trapFocus` and `blockScrollOnMount` are only switched off so that the preview works properly.
            blockScrollOnMount={false}
            trapFocus={false}
        >
            <ModalOverlay/>
            <ModalContent borderRadius="2xl" mx="4" bg={'#eee'}>
                <ModalBody>
                    <Stack
                        maxW="md"
                        mx="auto"
                        py={{base: '12', md: '16'}}
                        spacing={{base: '6', md: '5'}}
                    >
                        {/*<Logo/>*/}
                        <Stack spacing="3" textAlign="center">
                            {/*<Text fontSize="lg">Interested our product?</Text>*/}
                            <Text
                                color={useColorModeValue('blue.800', 'blue.200')}
                                fontWeight="extrabold"
                                fontSize={{base: '2xl', md: '3xl'}}
                                mb={2} pb={0}
                                lineHeight={'40px'}
                            >
                                Buy the HealthShield<br/>Premiere Luxury Network
                            </Text>
                            <Text fontSize="lg" mb={0}>
                                We are currently awaiting shipments of our product. Enter your email
                                to receive updates and 15% off your first order.
                            </Text>
                            <FormDataProvider>
                                <SubscribeForm onClose={onClose} color={color} size={size}/>
                            </FormDataProvider>
                            <Text fontSize="sm">
                                Your email will not be shared with anyone.
                            </Text>
                        </Stack>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
