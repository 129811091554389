import {Box, Flex, Heading, Img, Text} from '@chakra-ui/react'
// @ts-ignore
import image from '../../assets/property.webp'
import React from "react";

export const WithImageBackground = ({useH1}: { useH1?: boolean }) => {
    return (
        <Box bg="gray.800" as="section" minH="500px" position="relative">
            <Box py={{base: 16, lg: 32}} pb={{base: 8, lg: 32}} position="relative" zIndex={1} minH="500px"
                 justifyContent={'center'} display={'flex'}>
                <Box mx="auto" px={{base: '6', md: '8'}} color="white"
                     textAlign={'center'} justifyContent={'center'} display={'flex'} flexDirection={'column'}>
                    <Heading as={useH1 ? 'h1' : 'h2'} size="3xl" fontWeight="extrabold">
                        Premiere Luxury Network
                    </Heading>
                    <Text fontSize={{base: '1.5rem', md: '2.5rem'}} mt="4" textAlign={'center'} mx={'auto'}
                          fontWeight={'100'} maxW={'5xl'}>
                        Partnering with Property Owners to Maximize Value and Peace of Mind
                    </Text>
                </Box>
            </Box>
            <Flex
                id="image-wrapper"
                position="absolute"
                insetX="0"
                insetY="0"
                w="full"
                h="full"
                overflow="hidden"
                align="center"
            >
                <Box position="relative" w="full" h="full">
                    <Img
                        src={image}
                        alt="Premiere Luxury Network"
                        w="full"
                        h="full"
                        objectFit="cover"
                        objectPosition="66%"
                        position="absolute"
                    />
                    <Box position="absolute" w="full" h="full" bg="blackAlpha.700"/>
                </Box>
            </Flex>
        </Box>
    )
}
