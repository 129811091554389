import {Box, Container, Heading, Stack} from '@chakra-ui/react'
import {testimonials, testimonials2, testimonials3} from './data'
import {Testimonial} from './Testimonial'
import React from "react";

export const TestimonialsWithRating = () => (
    <Box as="section" py={{base: '16', md: '24'}} maxW={'full'} id={"testimonials"}>
        <Stack direction={{base: 'column', lg: 'column'}} spacing="16" maxW={'full'}>
            <Container maxW={'7xl'}>
                <Heading as="h2" size="3xl" maxW={'3xl'} fontWeight="extrabold" mb={2}>
                    What our clients say
                </Heading>
            </Container>
            <Container maxW={'7xl'}>
                <Stack direction={{base: 'column', lg: 'row'}} spacing="16">
                    {testimonials.map((testimonial, id) => (
                        <Testimonial key={id} {...testimonial} />
                    ))}
                </Stack>
            </Container>
            <Container maxW={'7xl'}>
                <Stack direction={{base: 'column', lg: 'row'}} spacing="16">
                    {testimonials2.map((testimonial, id) => (
                        <Testimonial key={id} {...testimonial} />
                    ))}
                </Stack>
            </Container>
            <Container maxW={'7xl'}>
                <Stack direction={{base: 'column', lg: 'row'}} spacing="16">
                    {testimonials3.map((testimonial, id) => (
                        <Testimonial key={id} {...testimonial} />
                    ))}
                </Stack>
            </Container>
        </Stack>
    </Box>
)
