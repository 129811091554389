import React from "react";
import {Box, Button, ButtonGroup, Container, Heading, HStack,} from '@chakra-ui/react'

export const NavbarWithPopoverOnAccent = () => (
    <Box as="section">
        <Box bg="bg.accent.default" position="relative" zIndex="tooltip">
            <Container py="4" minW={'full'}>
                <HStack justify="space-between" spacing="8">
                    <HStack spacing="5">
                        <HStack spacing="3">
                            {/*<MobileDrawer/>*/}
                            <Heading as={'span'} fontWeight={'extrabold'}>PLN</Heading>
                        </HStack>
                        <ButtonGroup
                            size="lg"
                            variant="text.accent"
                            colorScheme="gray"
                            spacing="3"
                            display={{base: 'none', lg: 'flex'}}
                        >
                            {/*<Button>Home</Button>*/}
                            <Button fontWeight={'200'} as={'a'} href={"#about"}>About</Button>
                            <Button fontWeight={'200'} as={'a'} href={"#services"}>Services</Button>
                            <Button fontWeight={'200'} as={'a'} href={"#compliance"}>Compliance</Button>
                            <Button fontWeight={'200'} as={'a'} href={"#faq"}>FAQ</Button>
                            <Button fontWeight={'200'} as={'a'} href={"#testimonials"}>Testimonials</Button>
                        </ButtonGroup>
                    </HStack>
                    <HStack spacing="10">
                        <Button as={'a'} href={'tel:5167764428'}>Contact Us</Button>
                    </HStack>
                </HStack>
            </Container>
        </Box>
    </Box>
)
